import PropTypes from 'prop-types';
import React from 'react';

import classy from '@core/utils/classy';
import { htmlBlock } from '@core/utils/magicBlocks';

import RDMD from '@ui/RDMD';

import c from '../style.module.scss';

const HtmlBlock = ({ html, rdmdOpts = {}, className = '' }) => {
  return (
    <RDMD className={classy(c.LandingBlock, c['LandingBlock-Html'], className)} opts={rdmdOpts}>
      {htmlBlock(html)}
    </RDMD>
  );
};

HtmlBlock.propTypes = {
  html: PropTypes.string,
  rdmdOpts: PropTypes.object,
};

export default HtmlBlock;
