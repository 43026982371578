import PropTypes from 'prop-types';
import React from 'react';

import classy from '@core/utils/classy';

import c from '../style.module.scss';

import TextBlock from './Text';

const ThreeBlock = ({ group0, group1, group2, className, ...props }) => (
  <div className={classy(c.LandingBlock, c['LandingBlock-Three'], className)}>
    <TextBlock {...props} {...group0} />
    <TextBlock {...props} {...group1} />
    <TextBlock {...props} {...group2} />
  </div>
);

ThreeBlock.propTypes = {
  group0: PropTypes.shape({
    text: PropTypes.string,
    title: PropTypes.string,
  }),
  group1: PropTypes.shape({
    text: PropTypes.string,
    title: PropTypes.string,
  }),
  group2: PropTypes.shape({
    text: PropTypes.string,
    title: PropTypes.string,
  }),
};

export default ThreeBlock;
