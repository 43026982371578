/* eslint-disable no-unused-vars
 */
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import { ConfigContext, ProjectContext } from '@core/context';
import useFeatureOptions from '@core/hooks/useFeatureOptions';
import useUserPermissions from '@core/hooks/useUserPermissions';
import classy from '@core/utils/classy';

import c from '../style.module.scss';

const singular = str => str.replace(/s$/, '');
const plural = str => `${singular(str)}s`;

const LinksBlock = ({ discuss, changelogs, docsCategories, className }) => {
  const { domainFull } = useContext(ConfigContext);
  const { project } = useContext(ProjectContext);
  const { modules } = project;
  const features = useFeatureOptions();
  const { isLoggedIn } = useUserPermissions();

  const lists = {
    docs: docsCategories,
    changelog: changelogs,
    discuss,
  };

  const nullcases = {
    discuss: (
      <>
        No {singular(features.discuss.toLowerCase())} posts found.{' '}
        <Link to="/discuss-new">
          <b>Ask a question</b>
        </Link>
        .
      </>
    ),
  };

  return (
    <div className={classy(c.LandingBlock, c['LandingBlock-Links'], className)}>
      {Object.keys(lists)?.map(key => {
        if (!modules?.[key]) return null;
        const max = 4;
        const feat = features?.[key] || key;
        const list = lists[key];
        const ATag = key === 'discuss' ? 'a' : Link;
        return (
          <div key={`LP-LinksBlock:${key}`} className="markdown-body">
            <h3>{feat}</h3>
            <ul>
              {!list?.length ? (
                nullcases[key] || `No ${plural(feat.toLowerCase())} found.`
              ) : (
                <>
                  {list?.slice(0, max)?.map(({ title, slug, _id, pages, ...i }) => {
                    // eslint-disable-next-line no-param-reassign
                    if (key === 'docs') slug = pages?.[0]?.slug || '';
                    const path = `/${key}/${slug || _id}`;
                    return (
                      <li key={`LP-LinksBlock:item-${_id}`}>
                        <ATag href={path} to={path}>
                          {title}
                        </ATag>
                      </li>
                    );
                  })}
                  <li>
                    <ATag className={classy(c['LandingBlock-link_viewAll'])} href={`/${key}`} to={`/${key}`}>
                      View All…
                    </ATag>
                  </li>
                </>
              )}
            </ul>
          </div>
        );
      })}
    </div>
  );
};

LinksBlock.propTypes = {
  changelogs: PropTypes.array,
  discuss: PropTypes.array,
  docsCategories: PropTypes.array,
};

export default LinksBlock;
