import PropTypes from 'prop-types';
import React from 'react';

import classy from '@core/utils/classy';
import { codeBlock } from '@core/utils/magicBlocks';

import RDMD from '@ui/RDMD';

import c from '../style.module.scss';

import HtmlBlock from './Html';
import TextBlock from './Text';

const TextMediaBlock = ({ mediaType, mediaImage, mediaCode, mediaHTML, side, rdmdOpts = {}, className, ...props }) => (
  <div
    className={classy(
      c.LandingBlock,
      c['LandingBlock-TextMedia'],
      side === 'right' ? c['LandingBlock-TextMedia_reverse'] : '',
      className,
    )}
  >
    {mediaType === 'html' && <HtmlBlock {...props} className={c['LandingBlock-TextMedia-media']} html={mediaHTML} />}
    {mediaType === 'image' && mediaImage?.length > 0 && (
      // eslint-disable-next-line jsx-a11y/alt-text
      <img className={c['LandingBlock-TextMedia-media']} src={mediaImage[0]} />
    )}
    {mediaType === 'code' && (
      <RDMD className={c['LandingBlock-TextMedia-media']} opts={rdmdOpts}>
        {codeBlock(mediaCode)}
      </RDMD>
    )}
    {!(props?.title || props?.text) || <TextBlock {...props} />}
  </div>
);

TextMediaBlock.propTypes = {
  mediaCode: PropTypes.shape({ codes: PropTypes.array }),
  mediaHTML: PropTypes.string,
  mediaImage: PropTypes.array,
  mediaType: PropTypes.string,
  rdmdOpts: PropTypes.object,
  side: PropTypes.oneOf(['right', 'left']),
  text: PropTypes.string,
  title: PropTypes.string,
};

export default TextMediaBlock;
