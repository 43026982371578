/* eslint-disable no-shadow
 */
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import classy from '@core/utils/classy';

import APIMethod from '@ui/API/Method';

import c from '../style.module.scss';

const DocsBlock = ({ docsCategories, refCategories, pageType, className }) => {
  const max = 3;
  const category = pageType === 'Reference' ? refCategories : docsCategories;
  const basePath = pageType === 'Reference' ? 'reference' : 'docs';

  if (!category || !category.length) return null;

  return (
    <div className={classy(c.LandingBlock, c['LandingBlock-Docs'], className)}>
      {category?.map(({ title: listTitle, pages, _id }) => (
        <div key={`LP-DocsBlock:${_id}`} className="markdown-body">
          <h3>{listTitle}</h3>
          <ul>
            {pages.slice(0, max).map(({ title, slug, _id, type, api = {}, ...doc }) => {
              if (type === 'link');
              const method = type === 'endpoint' ? api?.method : false;
              const Tag = type === 'link' ? 'a' : Link;

              // Ensure we correctly build path based on pageType
              const path = [`/${basePath}`];
              if (doc.pageType === 'RealtimePage') path.push('intro');
              path.push(slug);

              const uri = type === 'link' ? doc.link_url : path.join('/');
              const props = {
                href: uri,
                to: uri,
                target: type === 'link' && doc.link_external && '_blank',
              };
              return (
                <li key={`LP-DocsBlock:${_id}`}>
                  <Tag {...props}>{title}</Tag>
                  {!!method && <APIMethod fixedWidth type={method} />}
                </li>
              );
            })}
            {pages.length > max && (
              <li>
                <Link className={classy(c['LandingBlock-link_viewAll'])} to={`/${basePath}/${pages[0].slug}`}>
                  View More…{' '}
                </Link>
              </li>
            )}
          </ul>
        </div>
      ))}
    </div>
  );
};

DocsBlock.propTypes = {
  docsCategories: PropTypes.arrayOf(PropTypes.object),
  pageType: PropTypes.string,
  refCategories: PropTypes.arrayOf(PropTypes.object),
};

export default DocsBlock;
