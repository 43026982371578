import PropTypes from 'prop-types';
import React from 'react';

import classy from '@core/utils/classy';

import RDMD from '@ui/RDMD';

import c from '../style.module.scss';

const TextBlock = ({ title, text, rdmdOpts = {}, className }) => (
  <div className={classy(c.LandingBlock, c['LandingBlock-Text'], className)}>
    <RDMD opts={rdmdOpts}>{[title ? `## ${title}` : '', text].join('\n\n')}</RDMD>
  </div>
);

TextBlock.propTypes = {
  rdmdOpts: PropTypes.object,
  text: PropTypes.string,
  title: PropTypes.string,
};

export default TextBlock;
